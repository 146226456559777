// States & Config
import states from './background-section-modifier.state.js'

// Components
import SliderModifier from '../slider-modifier/Slider-modifier.vue'
import custColorPicker from '@/components/standalone/cust-color-picker/Cust-color-picker.vue'
import ImagePathModifier from '../image-path-modifier/Image-path-modifier.vue'

// Libraries
import {
  cloneDeep as _cloneDeep
} from 'lodash-es'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Settings-mj-section'

// Vue@Properties
const props = {
  mjml: Object,
  show: Boolean
}
// Vue@Properties

// Vue@data
const data = function () {
  return {
    states: _cloneDeep(states)
  }
}
// Vue@data

// Vue@subComponents
const components = {
  SliderModifier,
  custColorPicker,
  ImagePathModifier
}
// Vue@subComponents

// Methods
const methods = {
  /**
   * Generate image with correct size
   * @param {String} src (url)
   */
  _setImageProperties (src, isInit) {
    const img = new Image()
    img.onload = () => {
      this.states.tempWidth = `${img.width}px`
      this.states.maxWidth = img.width
      if (!isInit) this.width = img.width
    }
    img.src = src
  }
}

// Computed methods
const computed = {

  /**
   * Background url
   * @return {String} (url)
   */
  imageURL () {
    return this.mjml.attributes['background-url']
  },

  // Func@bgRepeat
  /**
   * Background-repeat
   */
  bgRepeat: {
    // getter
    get () {
      return this.mjml.attributes['background-repeat'] === 'repeat'
    },
    // setter
    set (boolean) {
      if (boolean) {
        this.$set(this.mjml.attributes, 'background-repeat', 'repeat')
        return
      }
      this.$set(this.mjml.attributes, 'background-repeat', 'no-repeat')
    }
  },
  // Func@bgRepeat

  autoWidth: {
    // getter
    get () {
      return this.states.widthAuto
    },
    // setter
    set (newValue) {
      this.states.widthAuto = newValue
      const size = newValue ? '100%' : this.states.tempWidth
      this.$set(this.mjml.attributes, 'background-size', size)
    }
  },

  width: {
    // getter
    get () {
      if (this.mjml.attributes['background-size'] !== '100%') {
        this.states.tempWidth = this.mjml.attributes['background-size']
      }
      const width = this.states.widthAuto
        ? this.states.tempWidth
        : this.mjml.attributes['background-size']

      return parseInt(width, 10)
    },
    // setter
    set (newValue) {
      if (this.autoWidth) return
      this.mjml.attributes['background-size'] = `${newValue}px`
    }
  }
}

// Vue@watchTemplate
const watch = {
  imageURL: function (newSrc) {
    if (!newSrc) return
    this._setImageProperties(newSrc)
    this.mjml.attributes['background-size'] = '100%'
  }
}
// Vue@watchTemplate

/**
 * Init widthAuto state
 */
function initState () {
  if (this.mjml.attributes['background-size'] !== '100%') {
    this.states.widthAuto = false
  }
  if (this.imageURL) {
    this._setImageProperties(this.imageURL, true)
  }
}

// Vue component syntax
export default {
  name,
  data,
  props,
  watch,
  methods,
  computed,
  components,
  created: initState
}
